import React, { useEffect, useState } from 'react'
import './MediaSlider'
import MediaSlider from './MediaSlider';
import zrceBeach from '../assets/zrce-beach.jpg'

export default function Screen2021() {
  function getCenter(element) {
    const {left, top, width, height} = element.getBoundingClientRect();
    return {x: left + width / 2, y: top + height / 2}
  }

  const [center, setCenter] = useState({x: 0, y: 0});
  const [isMobile, setIsMobile] = useState(false);




const NoaBg = {backgroundSize: "100vw 100vh", backgroundImage: `linear-gradient(to bottom, rgba(11, 11, 11, 0.6), rgba(255,255, 255, 0.2)), url(${zrceBeach})`}

// const NoaBg = {backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2)), url(${zrceBeach})`, height: "100%"}
// const NoaBg = {backgroundImage: `linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(255, 164, 233, 0.2)), url(${noa})`}

  // useEffect(() => {
  //   function handleResize() {
  //     let dev_width = window.innerWidth;
  //     console.log("dev_width", dev_width)
  //     setIsMobile(dev_width < 700);

  //     const arrow = document.querySelector("#arrow");
  //     const arrowCenter = getCenter(arrow);
  //     window.removeEventListener('mousemove', handleMouseMove)
  //     setCenter(() => arrowCenter)
  //   }

  //   window.self.addEventListener("resize", handleResize)

  //   return _ => {
  //     window.removeEventListener('resize', handleResize)
  //       }
  // },[])

  const handleMouseMove = ({clientX, clientY}) => {
        const arrow = document.querySelector("#arrow");
        let cent = center;
        let angle = Math.atan2(clientY - cent.y, clientX - cent.x);
        angle = angle + Math.PI / 2 ;
        arrow.style.transform = `rotate(${angle}rad)`;
  }

  // useEffect(() => {
  //   const arrow = document.querySelector("#arrow");
  //   const arrowCenter = getCenter(arrow);
  //   arrowCenter.y = arrowCenter.y - window.innerHeight
  //   if (center.y == 0 && center.x == 0) {
  //     setCenter(() => arrowCenter)
  //   }
  //   let dev_width = window.innerWidth;
  //   console.log("dev_width", dev_width)
  //   setIsMobile(dev_width < 700);

  //   window.self.addEventListener("mousemove", handleMouseMove);

  //   return function cleanUp() {
  //     window.removeEventListener('mousemove', handleMouseMove)
  //   }

  // },[center])

  // if (isMobile) {
  //   console.log("inside if isMobile", isMobile)
  //   // change height and width to 100%
  //   let circle = document.getElementById("circle")
  //   // circle.style.height = "100%"
  //   circle.style.width = "100%"
  //   circle.style.display = "flex"
  //   circle.style.flexDirection = "column"
  //   circle.style.alignItems = "center";
  //   let childList = circle.childNodes;
  //   childList.forEach(function(item){
  //     console.log("item", item)
  //     console.log("item.className", item.className)
  //     if (item.className.includes("deg-")) {
  //       item.style.position = "static"
  //       if(item.className.includes("deg-60")) {
  //         item.style.marginBottom = '15px'
  //       }
  //       else if (item.className.includes("deg-300")) {
  //         item.style.marginTop = '15px'
  //       }
  //       else {
  //         item.style.marginBottom = '15px'
  //         item.style.marginTop = '15px'
  //       }
  //     }
  //   });

  // }

  return (
    <>
      <div className="second-page-container" style={NoaBg} >
        <h1 className="novalja-2021" style={isMobile ? {fontSize: "58px", marginBottom: "10px"} :{fontSize: "58px", marginBottom: "5px",}} >Novalja 2021</h1>
        {/* <Fade delay={500} bottom> */}
          {/* <div id="circle" className="circle-wrapper"> */}
              {/* <img src={beluga} id="arrow" alt="logo" style={{position: "absolute", left: "46%", top: "25%"}}/> */}
          {/* </div> */}
          {/* </Fade>   */}
          <MediaSlider />
      </div>
    </>
      )
    }
