import React from 'react'
import "./start.css"

export default function Pager(props) {
      const pagesNumbers = [0,1]
  return (
    <div className="fp-right" id="fp-nav">
        <ul>
                {      
        pagesNumbers.map((el, index) => {
          // console.log("eee", el)
          // console.log("props.page", props.page)
              return <li className={props.page == el ? "pager-list-item active-page" : "pager-list-item"} key={index} onClick={() => props.handlePageChange(el)}>
                  <span className=''>

                  </span>
                </li>
        })
            }

        </ul>
    </div>
  )
}
