import logo from './logo.svg';
import './App.css';
import ReactPageScroller from 'react-page-scroller';
import {useState, useEffect} from 'react'
import Pager from "./novalja/Pager.js";
import StartScreen from './novalja/StartScreen';
import Screen2021 from './novalja/Screen2021';

function App() {
  const [page, setPage] = useState(0)

  const handlePageChange = (number) => {
    // console.log("number", number)
    setPage(number)
  };

  const handleBeforePageChange = (number) => {
    console.log(number);
  };
  return (
      <>
        <ReactPageScroller
          pageOnChange={handlePageChange}
          onBeforePageScroll={handleBeforePageChange}
          customPageNumber={page}
        >
          <StartScreen />
          <Screen2021 />
        </ReactPageScroller>
        <Pager handlePageChange={handlePageChange} page={page}>

        </Pager>

      </>
  );
}

export default App;
