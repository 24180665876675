import React from 'react'
import CountdownTimer from '../novalja/CountdownTimer';
import beluga from '../assets/beluga-bottle.png'
import noa from '../assets/novalja-zrce-noa.jpg'


const NoaBg = {backgroundSize: "100vw 100vh", backgroundImage: `linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(255, 164, 233, 0.2)), url(${noa})`}
const THREE_DAYS_IN_MS = 3 * 24 * 60 * 60 * 1000;
const goal_date = new Date(2022,6,14).getTime()
const NOW_IN_MS = new Date().getTime();

const dateTimeAfterThreeDays = goal_date;

export default function StartScreen() {
  return (
    <div className="App" >
      <header className="App-header" style={NoaBg}>
          <img src={beluga} className="App-logo" alt="logo" />
          <h1>Novalja 2022</h1>
          <CountdownTimer targetDate={dateTimeAfterThreeDays} />
      </header>
    </div>
  )
}
