import React, { useState } from 'react'

export default function MemoryBubble(props) {

    const handleMouseOver = (event) => {
        if (event.target.id == "doener" ||event.target.id == "bebba") {
            event.target.play()
        }
    }

    const handleMouseLeave = (event) => {
        if (event.target.id == "doener" ||event.target.id == "bebba") {
            event.target.pause()
        }
    }

    const [fullscreen, setFullscreen] = useState(false)
    
    const activateFullScreen = (event) => {
        console.log("event", event)
        console.log("event.target", event.target)
        let elem = event.target
        if (fullscreen) {
            setFullscreen(false)
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) { /* Safari */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE11 */
                document.msExitFullscreen();
            }
        }
        else {
                setFullscreen(true)
              if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) { /* Safari */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { /* IE11 */
                elem.msRequestFullscreen();
            }
        }

    }

  return (
    <div className="bubble-container">
        <div className="bubble-shape" onClick={props.video ? (e) => activateFullScreen(e) : (e) => activateFullScreen(e)} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
            {
                props.video ?
                    <video id={props.alt} width="100%" height="100%" name={props.alt} className="bubble-image" autoPlay="autoplay">
                        <source src={props.image} name={props.alt} className="bubble-image" type="video/mp4" />
                    </video>
                :
                    <img src={props.image} alt={props.alt} name={props.alt} className="bubble-image"></img>
            }
        </div>
    </div>
  )
}
