import React from 'react';
import Carousel from 'react-material-ui-carousel'
import { Paper, Button } from '@mui/material'

import beluga from '../assets/beluga-bottle.png'
import Fade from 'react-reveal/Fade';
import MemoryBubble from './MemoryBubble';
import Promises from '../assets/promise.jpg'
import Vomitting from '../assets/vomitting.jpeg'
import Attack from '../assets/denis_attack.jpeg'
import Doener from '../assets/doener.mp4'
import Bebba from '../assets/bebba.mp4'

function MediaSlider(props)
{
          
          

    var items = [
        {
            media: <div className="deg-60"> <MemoryBubble image={Promises} alt={'promise'}></MemoryBubble> </div>,
            description: "Probably the most random thing you have ever seen!"
        },
        {
            media: <div className="deg-120"> <MemoryBubble image={Vomitting} alt={'vomit'}></MemoryBubble> </div>,
            description: "Hello World!",
        },
        {
            media:   <div className="deg-180"> <MemoryBubble image={Attack} alt={'attack'}></MemoryBubble> </div>,
            description: "Hello World!",
        },
        {
            media:  <div className="deg-240"> <MemoryBubble image={Doener} alt={'doener'} video={true}></MemoryBubble> </div>,
            description: "Hello World!",
        },
        {
            media:  <div className="deg-300"> <MemoryBubble image={Bebba} alt={'bebba'} video={true}></MemoryBubble> </div>,
            description: "Hello World!",
        }

    ]

    return (
        <Carousel autoPlay={false} animation={"slide"} navButtonsAlwaysVisible={true} >
            {
                items.map( (item, i) => <Item key={i} item={item} /> )
            }
        </Carousel>
    )
}

function Item(props)
{
    return (
        // <Paper>
        //     <h2>{props.item.name}</h2>
        //     <p>{props.item.description}</p>

        //     <Button className="CheckButton">
        //         Check it out!
        //     </Button>
        // </Paper>
        <div>
          <>
            {props.item.media}
          </>  
            {/* <Paper sx={{color: "#000", backgroundColor: "transparent", boxShadow: "None"}}>
                <p>{props.item.description}</p>
            </Paper>  */}
        </div>
    )
}

export default MediaSlider;
    // <Carousel
    //     next={ (next, active) => console.log(`we left ${active}, and are now at ${next}`); }
    //     prev={ (prev, active) => console.log(`we left ${active}, and are now at ${prev}`); }
    // >
    //     {...}
    // </Carousel>

    // // OR

    // <Carousel
    //     next={ () => {/* Do stuff */} }
    //     prev={ () => {/* Do other stuff */} }
    // >
    //     {...}
    // </Carousel>

    // // And so on...
